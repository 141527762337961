/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

code {
  color: #e01a76;
}


 

.btn-blue {
  color: #fff;
  background-color: #2CA3DB;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-green {
  color: #fff;
  background-color: #9DC539;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-yellow {
  color: #fff;
  background-color: #FFBF00;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-blue-new-job-program {
  color: #fff;
  background-color: #2CA3DB;
  width: 150px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-blue-new-job-program2 {
  color: #fff;
  background-color: #2CA3DB;
  width: 150px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-blue-exoport-excel {
  color: #fff;
  background-color: #2CA3DB;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-gray {
  color: #fff;
  background-color: black;
  width: 100px;
  line-height: 25px !important;
  height: 25px !important;
  font-size: 12px;
  /*border-color: #1861ac;*/
}

.btn-gray2 {
  color: #fff;
  background-color: #7F7F7F;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*
    width: 36px;
    border-color: #1861ac;
  */
}

.btn-red {
  color: #fff;
  background-color: #DD4B39;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-sky {
  color: #fff;
  background-color: #00B0F0;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  /*border-color: #1861ac;*/
}

.btn-save {
  color: #fff;
  background-color: #5cb85c;
  width: 100px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-operator {
  color: #fff;
  background-color: #8397B0;
  width: 25px;
  font-size: 16px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-operator-active {
  color: #fff;
  background-color: #333F50;
  width: 25px;
  font-size: 16px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

.btn-clear-all {
  color: #fff;
  background-color: #8397B0;
  width: 65px;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  /*border-color: #1861ac;*/
}

button:focus, button:active:focus, button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.dt-button.dt-btn-blue {
  color: #fff;
  background-color: #2CA3DB;
  cursor: pointer;
}

.blue-icon {
  color: #2CA3DB;
}
/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.icon-icon svg {
  fill: #2CA3DB;
}

.gray-icon {
  color: black;
}
  /* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
  .gray-icon svg {
    fill: black;
  }

.orange-icon {
  color: #F15642;
}
  /* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
  .orange-icon svg {
    fill: #F15642;
  }

.mat-sidenav-content {
  background-image: url("assets/images/SDBC_BG.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}

.container-modal {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 0;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mat-dialog-container {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-toolbar-row {
  height: 24px;
}

.content-sdb {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 75vh;
  /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
}


/* Roboto regular */
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.eot");
  src: url("/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'), url("/fonts/Roboto-Regular.woff2") format('woff2'), url("/fonts/Roboto-Regular.woff") format('woff'), url("/fonts/Roboto-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}


/* Roboto bold */

@font-face {
  font-family: "Roboto";
  src: url('/fonts/Roboto-Bold.eot');
  src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff'), url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.table thead,
.table th {
  text-align: center;
}

/* Ppanel and form field */

.mat-expansion-panel-header,
.mat-expansion-panel-header:hover,
.mat-expansion-panel-header:focus,
.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
  /* background: linear-gradient(90deg, rgba(42,87,156,1) 0%, rgba(107,142,190,1) 69%, rgba(193,204,207,1) 100%) !important; */
  height: 45px !important;
  border-radius: 1%;
}

.mat-expansion-panel-header-description .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: white !important;
}

.mat-horizontal-content-container {
  padding-top: 0.1em !important;
}

.mat-expansion-panel-body {
    padding : 16px !important;
}

/* Forn field */
/*
.mat-form-field-flex > .mat-form-field-infix {
  padding: 0px !important;
  border-top: 0 !important;
}

.mat-form-field-infix {
  padding: 0px !important;
  border-top: 0 !important;
}
*/
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0;
}

.mat-form-field-infix {
  border-top-width: 0em !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0px !important;
}

.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.25);
  width: 133.33333%;
}


.mat-form-field-wrapper {
  margin-bottom: 0 !important;
  margin-top: 0.5em !important;
  padding-bottom: 0.5em;
}

.mat-expansion-panel-header-title {
  /*font-weight: bold !important;*/
  font-size: 15px !important;
}

.mat-expansion-panel-content {
  font-size: 12px !important;
}

.mat-body, .mat-body-1, .mat-typography {
  font-size: 12px !important;
}
/*.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #039be5;
}*/
/*material table*/
.mat-header-cell {
  font-size: 12px;
  color: black;
}

.mat-cell {
  font-size: 12px;
}
/*material table*/


/*header menu*/
.header-menu-active {
  color: #2CA3DB;
  font-size: 15px;
  font-weight: bold;
}

.header-menu {
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.example-spacer {
  flex: 1 1 auto;
}

.mat-checkbox-layout {
  margin: 0;
}

table {
  width: 100%;
}

  table.dataTable tbody th, table.dataTable tbody td {
    padding: 4px 5px;
  }

  table.dataTable thead th, table.dataTable thead td {
    padding-top: 8px;
  }

  table.dataTable {
    margin: 0 0 !important;
  }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.5em 0.5em;
}

.paginate_page {
  margin-left: 5px;
}

.paginate_of {
  margin-right: 5px;
}

/*.header-menu .mat-icon {
    font-size: 45px;
  }*/
/*.header-menu span {
    position: absolute;
    margin-top: 5px;
    margin-left: 30px; /* optional */
/* }*/
/*header menu*/
.text-input {
  font-size: 12px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.paginate_page {
  margin-right: 5px;
}

.paginate_of {
  margin-left: 5px;
}

.button-margin-bottom {
  margin-bottom: 5px !important;
}

.mat-error {
  margin-top: 14px;
  margin-left: -1em !important;
  margin-right: -1em !important;
}

.mat-form-field-subscript-wrapper {
  font-size: 90% !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

/*change color input*/
/*.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: black !important;
  border-radius: 5px;
}*/

.mat-form-field-disabled .mat-form-field-outline {
  background-color: #D9D9D9 !important;
  border-radius: 5px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0074FF !important;
}

.datatable-button-disabled {
  background-color: #D9D9D9 !important;
  border-radius: 5px;
}


div.dataTables_wrapper div.dataTables_length select {
  height: 32px;
  border-radius: 5px;
  text-align: center;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #0074FF !important;
}

.auto-width-height-modal {
  width: auto !important;
  height: auto !important;
}

/*
.mat-tab-label {
  height: 32px !important;
  border: solid;
  min-width: 120px !important;
}
*/

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2CA3DB;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0074FF;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #0074FF;
  background-color: #0074FF;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #0074FF;
}

.main-container {
  max-width: 100%;
  padding: 1rem 2rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.25rem;
}

.mat-menu-panel {
  min-width: 112px;
  max-width: 380px !important;
}

